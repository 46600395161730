import {toMap} from '../utils';
import {isBoolean, isShape, isUndefined, or, isString} from '../utils/basicValidators';

export type IServerInfo = {
  service?: string;
  defaultService?: string;
  useMultiservice?: boolean;
};

const isServerInfo = isShape<IServerInfo>({
  service: or(isUndefined, isString),
  defaultService: or(isUndefined, isString),
  useMultiservice: or(isUndefined, isBoolean)
});

export const checkAuth = (checkAuthPath: string, onServerInfo: (info: IServerInfo) => void) => (): Promise<boolean> => {
  return fetch(checkAuthPath)
    .then((res) => {
      if (res.status === 200 && res.headers.get('content-type')?.startsWith('application/json')) {
        return res.json();
      } else {
        throw Error('Unexpected response');
      }
    })
    .then((data) => {
      if (isServerInfo(data)) {
        onServerInfo(data);
        if (isString(data.service)) {
          return true;
        }
      }
      return false;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
};

export const login = (loginPath: string) => (username: string, password: string, service?: string): Promise<number> => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  if (isString(service)) {
    formData.append('service', service);
  }
  return fetch(loginPath, {
    method: 'POST',
    body: formData
  }).then((res) => res.status);
};

export const logout = (logoutPath: string, getRequestHeaders: () => [string, string][]) => (): Promise<number> => {
  return fetch(logoutPath, {
    headers: getRequestHeaders()
  }).then((res) => res.status);
};

export interface IUserInfo {
  userId: string;
  emailVerified: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  picture: string;
}

export const getUserInfo = (userInfoPath: string, getRequestHeaders: () => [string, string][]) => (): Promise<IUserInfo> => {
  return fetch(userInfoPath, {
    headers: getRequestHeaders()
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error(`Request status code is ${res.status}`);
    }
  });
};

export interface IPermission {
  scopes: string[];
  resourceId: string;
  entityId: string;
}

export const getUserPermissions = (userPermissionsPath: string, getRequestHeaders: () => [string, string][]) => (): Promise<IPermission[]> => {
  return fetch(userPermissionsPath, {
    headers: getRequestHeaders()
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error(`Request status code is ${res.status}`);
    }
  });
};

export const getPermissionsByIds = (userPermissionsPath: string, getRequestHeaders: () => [string, string][]) => (ids: string[]): Promise<IPermission[]> => {
  if (ids.length <= 0) {
    return Promise.resolve([]);
  }
  return fetch(`${userPermissionsPath}?resources=${ids.join(';')}`, {
    headers: getRequestHeaders()
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    console.error('Unexpected status code');
    return [];
  });
};

export const getPermissionsByIdsMap = (
  userPermissionsPath: string,
  getRequestHeaders: () => [string, string][]
): ((ids: string[], key?: keyof Omit<IPermission, 'scopes'>) => Promise<Map<string, string[]>>) => {
  const fetchByIds = getPermissionsByIds(userPermissionsPath, getRequestHeaders);
  return (ids, key = 'entityId') => {
    return fetchByIds(ids).then((permissions) =>
      toMap(
        permissions,
        (e) => e[key],
        (e) => e.scopes
      )
    );
  };
};

export const getPermittedScopes = (userPermissionsPath: string, getRequestHeaders: () => [string, string][]) => (scopes: string[]): Promise<string[]> => {
  return Promise.all(
    scopes.map((scope) =>
      fetch(`${userPermissionsPath}?resources=_${scope}&mode=decision`, {headers: getRequestHeaders()})
        .then((response) => {
          if (response.status === 200) {
            return scope;
          }
          throw Error('Unexpected status code');
        })
        .catch(() => null)
    )
  ).then((resolved) => resolved.filter(isString));
};
