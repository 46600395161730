import './set-public-path';
import {getPaths} from './getPaths';
import * as auth from './user/auth';
import {INGSIConnectionOptions, NGSIConnectionPool} from './ngsi/NgsiConnectionPool';
import {UnauthorizedEmitter} from './user/UnauthorizedEmitter';
import {keys, memoize} from './utils';
import {Connection} from 'ngsijs';
import {IPermission, IServerInfo, IUserInfo} from './user/auth';
import {SharedNotificationSocket} from './sockets/SharedNotificationSocket';
import {getServiceOptions, IServerContext, IServiceOptions, makeGetFiwareHeaders} from './IServerContext';

// eslint-disable-next-line
// @ts-ignore
import {getServerContext as getServerContextNew} from '@netvision/lib-api';

declare global {
  interface Window {
    __NV_CONSTANTS__:
      | {
          gatewayPath?: string;
          fiware?: {
            service: string;
            servicePath: string;
          };
        }
      | undefined;
  }
}

const _gatewayPath = window.__NV_CONSTANTS__?.gatewayPath || '/gateway';
/**
 * @deprecated
 */
export const gatewayPath = _gatewayPath;

const _fiwareOptions: IServiceOptions = window.__NV_CONSTANTS__?.fiware || {service: '', servicePath: ''};
/**
 * @deprecated
 */
export const fiwareOptions: INGSIConnectionOptions = _fiwareOptions;

const _serverContext: IServerContext = {
  gatewayPath: _gatewayPath,
  service: _fiwareOptions.service,
  servicePath: _fiwareOptions.servicePath,
  defaultService: undefined,
  useMultiservice: undefined
};

// This is used to keep compatibility with lib-api
getServerContextNew().then((context) => {
  if (context.service) {
    fiwareOptions.service = context.service;
    _serverContext.service = context.service;
  }
  _serverContext.defaultService = context.defaultService;
  _serverContext.useMultiservice = context.useMultiservice;
});

const onServerInfo = (info: IServerInfo) => {
  if (info.service) {
    fiwareOptions.service = info.service;
    _serverContext.service = info.service;
  }
  _serverContext.defaultService = info.defaultService;
  _serverContext.useMultiservice = info.useMultiservice;
};

export const getServerContext = (): Readonly<IServerContext> => Object.freeze({..._serverContext});
export const getFiwareHeaders = makeGetFiwareHeaders(getServerContext);

const paths = getPaths(getServerContext().gatewayPath);
const protectedUrls = keys(paths)
  .map((key) => paths[key])
  .filter((path) => path.protected)
  .map((path) => path.value);

const unauthorizedEmitter = new UnauthorizedEmitter(401, protectedUrls);

export const addOnUnauthorizedListener: (func: () => void) => () => void = unauthorizedEmitter.addListener.bind(unauthorizedEmitter);

export const checkAuth: () => Promise<boolean> = auth.checkAuth(paths.serverInfo.value, onServerInfo);

/** @deprecated */
export const checkConnection: () => Promise<number> = () => fetch(paths.orionVersion.value).then((res) => res.status);

export const login: (username: string, password: string, service: string) => Promise<number> = auth.login(paths.login.value);

export const logout: () => Promise<number> = auth.logout(paths.logout.value, getFiwareHeaders);

export const getUserInfo: () => Promise<IUserInfo> = auth.getUserInfo(paths.userInfo.value, getFiwareHeaders);

export const getUserPermissions: () => Promise<IPermission[]> = auth.getUserPermissions(paths.userInfo.value, getFiwareHeaders);

export const getPermissionsByIds: (entityOrResourceIds: string[]) => Promise<IPermission[]> = auth.getPermissionsByIds(
  paths.userPermissions.value,
  getFiwareHeaders
);

export const getPermissionsByIdsMap: (
  entityOrResourceIds: string[],
  targetKeyId?: keyof Omit<IPermission, 'scopes'>
) => Promise<Map<string, string[]>> = auth.getPermissionsByIdsMap(paths.userPermissions.value, getFiwareHeaders);

export const getPermittedScopes: (scopes: string[]) => Promise<string[]> = auth.getPermittedScopes(paths.userPermissions.value, getFiwareHeaders);

const connectionPool = new NGSIConnectionPool();

export const createCamerasConnection = (options = getServiceOptions(getServerContext())): Connection => connectionPool.get(paths.cameras.value, options);
/**
 * @deprecated
 */

export const createCoordinatorConnection = (options = getServiceOptions(getServerContext())): Connection =>
  connectionPool.get(paths.coordinator.value, options);

export const createNotificationsConnection = (options = getServiceOptions(getServerContext())): Connection =>
  connectionPool.get(paths.notifications.value, options);

export const getSharedNotificationSocket = memoize(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (key?: string) => new SharedNotificationSocket(paths.notificationSocket.value, createNotificationsConnection())
);
export * from './ngsi';
export * from './models';
export * from './utils';

export {IPermission, IUserInfo} from './user/auth';
